import React, { useState, useRef, useEffect } from "react";
// import Helmet from 'react-helmet';
// import { BrowserRouter as Router, Switch, Route, Link, useLocation } from "react-router-dom";

import { StaticImage } from "gatsby-plugin-image";

import { graphql, Link } from "gatsby";

import { List, ListItem, ListItemText } from "@material-ui/core";

import Grid from "@material-ui/core/Grid";
import NiinaHeader from "../components/NiinaHeader";
import NiinaFooter from "../components/NiinaFooter";

import { Helmet } from "react-helmet-async";

function Index({ data }) {
  return (
    <>
      <Helmet>
        <html lang="ja" />
        <title>Bakery Niina / ベーカリー・ニーナ</title>
        <meta
          name="description"
          content="山形のみなさんに、パン職人が一晩かけて仕込むこだわりパンを一番おいしい状態で届けたい。焼き立てパンが香る店『Bakery Niina（ベーカリーニーナ）』です。多
い日には１日５０回焼き上げるため、いつでも焼きたてのパンが楽しめます。"
        />
      </Helmet>
      <div style={{ minWidth: "320px" }}>
        <NiinaHeader />
        <IndexComponent data={data} />
        <NiinaFooter />
      </div>
    </>
  );
}

export default Index;

function IndexComponent({ data }) {
  const news = data.allMicrocmsNews.edges;
  console.log(news);

  return (
    <div className="App">
      <Grid
        direction="row"
        justify="space-between"
        alignItems="flex-start"
        container
        style={{
          margin: 0,
          padding: 0,
          minWidth: "320px",
          position: "relative",
        }}
      >
        <div style={{ position: "relative" }}>
          <StaticImage
            placeholder="none"
            style={{ minWidth: "320px", width: "100vw" }}
            src="../images/img/photos/01.top.jpg"
          />
        </div>
        <StaticImage
          placeholder="none"
          style={{
            transform: "translate(-50%, -50%)",
            top: "50%",
            left: "50%",
            position: "absolute",
            minWidth: "120px",
            width: "33vw",
            height: "33vw",
          }}
          src="../images/SVG/00.niina-round-logo.svg"
        />
      </Grid>

      <div style={{ position: "relative", width: "100vw" }}>
        <StaticImage
          placeholder="none"
          imgStyle={{
            height: "100%",
            width: "auto",
            margin: "auto",
          }}
          style={{
            height: "12vw",
            width: "100vw",
            marginTop: "3.125vw",
            marginBottom: "3.125vw"
          }}
          src="../images/SVG/01.message.svg"
        />
      </div>

      <Grid
        direction="row"
        justify="space-between"
        alignItems="flex-start"
        container
        style={{
          margin: 0,
          padding: 0,
          minWidth: "320px",
          position: "relative",
        }}
      >
        <StaticImage
          placeholder="none"
          style={{ width: "33%" }}
          src="../images/img/photos/パン.jpg"
        />
        <StaticImage
          placeholder="none"
          style={{ width: "33%" }}
          src="../images/img/photos/パン2.jpg"
        />
        <StaticImage
          placeholder="none"
          style={{ width: "33%" }}
          src="../images/img/photos/パン3.jpg"
        />
      </Grid>

      <div style={{ fontSize: mainFontSize }}>
        <p>
          パン職人が一晩かけて仕込むこだわりパンを
          <br />
          オーブンから出てきたばかりの、
          <br />
          一番おいしい状態で届けたい。
        </p>

        <p>
          焼き立てパンが香る店
          <br />
          『Bakery Niina（ベーカリーニーナ）』です。
        </p>
        <p>
          焼き立てパンの「サクッ」「フワッ」とした感じは、
          <br />
          手作りのベーカリーでしか味わえない特別なもの。
        </p>
        <p>
          当店では、フランスの伝統製法により、
          <br />
          いつでも焼き立てのパンが楽しめます。
          <br />
          多い日には１日５０回焼き上げるため、
          <br />
          店内は焼き立てのパンの香ばしい香りでいっぱいです。
        </p>
        <p>
          毎日、試行錯誤しながら、
          <br />
          来店されるたびに驚いてもらえる、
          <br />
          そんなパン作りを目指しています。
        </p>
      </div>

      <div
        style={{
          marginTop: "3.2em",
          marginBottom: "1.6em",
          display: "row",
          fontSize: "3.125vw",
        }}
      >
        <StaticImage
          placeholder="none"
          imgStyle={{
            height: "100%",
            width: "auto",
            margin: "auto",
          }}
          style={{
            height: "6vw",
            width: "100vw",
          }}
          src="../images/SVG/02.oshirase.svg"
        />
      </div>
      <div
        style={{
          overflow: "scroll",
          display: "flex",
          padding: "1em",
          height: "6em",
          display: "row",
          fontSize: "3.125vw",
          background: "#FFF6D0",
          textAlign: "left",
        }}
      >
        {news.map((e) => {
          return <p style={{ margin: 0, padding: 0 }}>{e.node.body}</p>;
        })}
        {/* <p style={{ margin: 0, padding: 0 }}>
          2020年06月29日 ホームページ オープン
        </p>
        <p style={{ margin: 0, padding: 0 }}>
          2020年06月17日 店舗 グランドオープン
        </p>
        <p style={{ margin: 0, padding: 0 }}>
          2020年06月10日 店舗 プレオープン
        </p> */}
      </div>

      <div
        style={{
          marginTop: "3.2em",
          marginBottom: "1.6em",
          display: "row",
          fontSize: "3.125vw",
        }}
      >
        {/* <StaticImage
      style={{ minWidth: "160px", maxWidth: "80%" }}
      src="../images/SVG/03.line-yoyaku.svg"
    /> */}
        <div style={{ display: "row", fontSize: "3.125vw" }}>
          <Link to="/professionalism">
            <StaticImage
              placeholder="none"
              style={{ margin: "1em", width: "40%" }}
              src="../images/img/3x/08.card-kodawari.jpg"
            />
          </Link>
          <a href="https://www.facebook.com/bakeryniina/photos">
            <StaticImage
              placeholder="none"            
              style={{ margin: "1em", width: "40%" }}
              src="../images/img/3x/09.card-photo.jpg"
            />
          </a>
        </div>
        <StaticImage
          placeholder="none"            
          layout="constrained"
          style={{ width: "30%", minWidth: "80px",  height: "auto" }}
          imgStyle={{ width: "auto", height: "100%" }}
          src="../images/SVG/00.niina-logo.svg"
        />
      </div>
    </div>
  );
}

const mainFontSize = "3.8vw";

export const query = graphql`
  {
    allMicrocmsNews(sort: { fields: [date], order: DESC }) {
      edges {
        node {
          id
          date
          body
        }
      }
    }
  }
`;
